import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewRun } from "./RunCreationModal";
import { checkDateBefore, convertToDateString } from "../../utils/DateUtils";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextFieldControl from "../../components/TextFieldControl/TextFieldControl";
import DatePickerControl from "../../components/DatePickerControl/DatePickerControl";
import NewRunInputGridItemContainer from "./Components/NewRunInputGridItemContainer/NewRunInputGridItemContainer";
import NewRunNavGridContainer from "./Components/NewRunNavGridContainer/NewRunNavGridContainer";
import NewRunFormLabel from "./Components/NewRunFormLabel/NewRunFormLabel";
import NewRunInputGridItem from "./Components/NewRunInputGridItem/NewRunInputGridItem";
import { ModelTiming } from "../../models/run";

interface SelectModelTimingStepProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: (data: { [key: string]: any }) => void;
  handleReset: () => void;
  handleClose: () => void;
  newRun: NewRun;
  previousModelTiming: ModelTiming;
}

export default function SelectModelTimingStep({
  activeStep,
  handleBack,
  handleNext,
  handleReset,
  handleClose,
  newRun,
  previousModelTiming,
}: SelectModelTimingStepProps) {
  // Define Zod schema for form validation
  const schema = z
    .object({
      modelTiming: z.object({
        runDate: z.date().refine((val) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return val < today;
        }, "Run date cannot be in the future"),
        startDate: z.date(),
        endDate: z.date(),
        daysInYear: z.coerce
          .number()
          .int()
          .positive()
          .min(365)
          .max(366, "Can only be 365 or 366"),
      }),
    })
    .refine(
      (schema) => {
        return (
          schema.modelTiming.runDate >= schema.modelTiming.startDate &&
          schema.modelTiming.startDate < schema.modelTiming.endDate &&
          schema.modelTiming.runDate <= schema.modelTiming.endDate
        );
      },
      {
        message: "Run date must fall between start and end date",
        path: ["modelTiming.runDate"],
      },
    )
    .refine(
      (schema) => {
        return checkDateBefore(
          previousModelTiming.runDate,
          schema.modelTiming.runDate,
        );
      },
      {
        message: "Run date cannot be before previous model date",
        path: ["modelTiming.runDate"],
      },
    );

  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      modelTiming: {
        runDate: new Date(newRun.modelTiming.runDate),
        startDate: new Date(newRun.modelTiming.startDate),
        endDate: new Date(newRun.modelTiming.endDate),
        daysInYear: newRun.modelTiming.daysInYear,
      },
    },
  });

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleNext({
            ...data,
            modelTiming: {
              runDate: convertToDateString(data.modelTiming.runDate),
              startDate: convertToDateString(newRun.modelTiming.startDate),
              endDate: convertToDateString(newRun.modelTiming.endDate),
              daysInYear: data.modelTiming.daysInYear,
            },
          });
        })}
      >
        <NewRunInputGridItemContainer>
          {/* RUN DATE*/}
          <NewRunFormLabel label={"Run Date"} />
          <NewRunInputGridItem>
            <DatePickerControl name="modelTiming.runDate" control={control} />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* START DATE*/}
          <NewRunFormLabel label={"Model Start Date"} />
          <NewRunInputGridItem>
            <DatePickerControl name="modelTiming.startDate" control={control} />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* END DATE*/}
          <NewRunFormLabel label={"Model End Date"} />
          <NewRunInputGridItem>
            <DatePickerControl name="modelTiming.endDate" control={control} />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* DAYS IN YEAR*/}
          <NewRunFormLabel label={"Days in year"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="modelTiming.daysInYear"
              control={control}
              placeholder="Days in year"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          <NewRunNavGridContainer>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                handleReset();
                handleClose();
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <ProgressButton
              inProgress={false}
              text="Next"
              Icon={NavigateNextIcon}
              color="#ffffff"
              backgroundColor="#2040cd"
            />
          </NewRunNavGridContainer>
        </NewRunInputGridItemContainer>
      </form>
    </>
  );
}
