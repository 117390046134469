import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import StyledTextField from "../StyledTextField/StyledTextField";

interface TextFieldControlProps {
  name: string;
  control: any;
  placeholder: string;
  type?: string;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

function TextFieldControl({
  name,
  control,
  placeholder,
  type = "number",
  endAdornment,
  disabled = false,
  required = true,
}: TextFieldControlProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur, ref },
        fieldState: { error },
      }) => (
        <FormControl fullWidth>
          <StyledTextField
            name={name}
            placeholder={placeholder}
            required={required}
            type={type}
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error)}
            disabled={disabled}
            endAdornment={endAdornment}
          />
          <FormHelperText
            sx={{
              color: "error.main",
            }}
          >
            {error?.message ?? ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default TextFieldControl;
