import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Tabs,
  Tab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Run } from "../../models/run";
import InfoMultiTable from "../InfoMultiTable/InfoMultiTable";
import {
  modelParamsRowHeaders,
  navBeforePerfRowHeaders,
  navPostPerfRowHeaders,
} from "./AuditReportHeaders";
import { fNumber } from "../../utils/formatNumber";
import ProgressButton from "../ProgressButton/ProgressButton";
import { useAppDispatch, useAppSelector } from "../../store/store";
import DownloadIcon from "@mui/icons-material/Download";
import { getExcelModel } from "../../store/excelModel/excelModelFunctions";
import { handleDownloadFile } from "../../utils/fileUtils";

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  runs: Array<Run>;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      width="100%"
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Grid>
  );
}

function customTabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Audit Report Modal
export default function AuditReportModal({
  open,
  handleClose,
  runs,
}: ModalProps) {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const models = runs.length ? runs.map((run) => run.metadata) : [];
  const loading = useAppSelector((state) => state.excelModel.loading);
  const runId = runs.length ? runs[runs.length - 1].id : -1;

  const handleExcelDownload = async () => {
    const res: any = await dispatch(getExcelModel(runId));
    if (!res.error) {
      handleDownloadFile(`model-${runId}.xlsx`, res.payload.data);
    }
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            height: "100%",
            maxWidth: "60%",
            maxHeight: "80%",
          },
        },
      }}
    >
      <DialogTitle>Pricing Audit</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setActiveTab(0);
          handleClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ height: "100%" }}>
        <Grid container rowSpacing={4} alignItems="center">
          <Grid item>
            <ProgressButton
              inProgress={loading}
              text={"Check in Excel"}
              Icon={DownloadIcon}
              color="#ffffff"
              backgroundColor="#2040cd"
              onClick={async () => {
                await handleExcelDownload();
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Before Perf Fees" {...customTabProps(0)} />
                <Tab label="Post Perf Fees" {...customTabProps(1)} />
                <Tab label="Model Params" {...customTabProps(2)} />
                <Tab label="Distributions" {...customTabProps(3)} />
              </Tabs>
            </Box>
          </Grid>
          {models.length && (
            <Grid item container rowSpacing={4} alignItems="center">
              <CustomTabPanel value={activeTab} index={0}>
                <InfoMultiTable
                  data={models}
                  rowHeaders={navBeforePerfRowHeaders(
                    models[models.length - 1],
                  )}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={1}>
                <InfoMultiTable
                  data={models}
                  rowHeaders={navPostPerfRowHeaders}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={2}>
                <InfoMultiTable
                  data={models}
                  rowHeaders={modelParamsRowHeaders}
                />
              </CustomTabPanel>
              <CustomTabPanel value={activeTab} index={3}>
                {runs.length &&
                  runs.map((run, index) => (
                    <Grid
                      item
                      container
                      direction="column"
                      key={index}
                      paddingBottom={4}
                      xs={6}
                    >
                      <Grid item>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", pb: 2 }}
                        >
                          Monthly Distributions as of {run.modelDate.toString()}
                        </Typography>
                      </Grid>
                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableBody>
                            {run.metadata.unitPriceDataModel
                              .monthlyDistributions.length &&
                              run.metadata.unitPriceDataModel.monthlyDistributions.map(
                                (distribution) => (
                                  <TableRow key={distribution.date.toString()}>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                      }}
                                    >
                                      {distribution.date.toString()}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                      }}
                                    >
                                      {fNumber(
                                        distribution.amountPerUnit,
                                        4,
                                        4,
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ),
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ))}
              </CustomTabPanel>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
