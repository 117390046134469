import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import React from "react";

type StyledTextFieldProps = TextFieldProps & {
  endAdornment?: React.ReactNode;
};

function StyledTextField(props: StyledTextFieldProps) {
  return (
    <TextField
      {...props}
      size="small"
      InputLabelProps={{ shrink: false }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.endAdornment}</InputAdornment>
        ),
        inputProps: {
          style: {
            MozAppearance: "textfield",
          },
        },
        sx: {
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none",
              margin: 0,
            },
        },
      }}
    />
  );
}

export default StyledTextField;
