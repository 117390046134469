import {
  Box,
  Divider,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Control } from "react-hook-form";
import { FeeLimit, FundAssumptions } from "../../models/fundDetails";
import {
  CALCULATION_TYPES,
  FEE_TYPES,
  FEE_TYPE_MAP,
  RATE_TYPES,
  MGT_FEE_TYPES,
} from "./EditFundSchema";
import ColumnItem from "./Components/ColumnItem/ColumnItem";
import FormRowGridContainer from "./Components/FormRowGridContainer/FormRowGridContainer";
import FormLabel from "./Components/FormLabel/FormLabel";
import MultiColumnContainer from "./Components/MultiColummContainer/MultiColumnContainer";
import AccordionLabel from "./Components/AccordionLabel/AccordionLabel";
import StyledAccordion from "./Components/StyledAccordion/StyledAccordion";
import StyledAccordionSummary from "./Components/StyledAccordionSummary/StyledAccordionSummary";
import StringSelectControl from "./Components/StringSelectControl/StringSelectControl";
import FormRowGridItem from "./Components/FormRowGridItem/FormRowGridItem";
import StyledAccordionDetails from "./Components/StyledAccordionDetails/StyledAccordionDetails";
import TextFieldControl from "../../components/TextFieldControl/TextFieldControl";
import DatePickerControl from "./Components/DatePickerControl/DatePickerControl";
import CheckboxControl from "../../components/CheckboxControl/CheckboxControl";
import { fCurrency } from "../../utils/formatNumber";
import { StyledButton } from "../../components/StyledButton/StyledButton";
import StyledTextField from "../../components/StyledTextField/StyledTextField";
import { Close as CloseIcon } from "@mui/icons-material";

interface EditFundFormProps {
  control: Control<FundAssumptions>;
  formValues: FundAssumptions;
  managementFeeLimits: Array<FeeLimit>;
  setManagementFeeLimits: (value: Array<FeeLimit>) => void;
}

const FormBox = styled(Box)({
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "calc(100vh - 222px)",
});

export default function EditFundForm({
  control,
  formValues,
  managementFeeLimits,
  setManagementFeeLimits,
}: EditFundFormProps) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({
    nonPerfFeePanel: true,
    perfFeePanel: true,
    prevPeriodPanel: true,
    mgtFeePanel: true,
  });

  const handleChange = (panel: string) => (event: React.SyntheticEvent) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: !prevExpanded[panel],
    }));
  };

  const [newLowerLimit, setNewLowerLimit] = useState<number>(0);
  const [newUpperLimit, setNewUpperLimit] = useState<number>(0);
  const [newFeeAmount, setNewFeeAmount] = useState<number>(0);

  const handleAddFeeLimit = () => {
    let updatedFeeLimits = [
      ...managementFeeLimits,
      {
        lowerLimit: newLowerLimit,
        upperLimit: newUpperLimit,
        feeAmount: newFeeAmount,
      },
    ];
    setManagementFeeLimits(updatedFeeLimits);
    setNewLowerLimit(0);
    setNewUpperLimit(0);
    setNewFeeAmount(0);
  };

  const handleRemoveFeeLimit = (index: number) => {
    const updatedFeeLimits = managementFeeLimits.filter((_, i) => i !== index);
    setManagementFeeLimits(updatedFeeLimits);
  };

  return (
    <FormBox>
      <StyledAccordion expanded={expanded["nonPerfFeePanel"]}>
        <StyledAccordionSummary handleChange={handleChange("nonPerfFeePanel")}>
          <AccordionLabel onClick={handleChange("nonPerfFeePanel")}>
            Non Performance Fees
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Calculation Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.calculationType"}
                    control={control}
                    options={CALCULATION_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Admin Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.adminFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .adminFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Admin Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.adminFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IEE Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.ieeFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .ieeFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IEE Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.ieeFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="RE Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.reFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .reFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="RE Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.reFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Registry Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.registryFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .registryFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Registry Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.registryFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IMF Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.imfFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .imfFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="IMF Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.imfFeeType"}
                    control={control}
                    options={FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
          <Divider sx={{ mb: 2 }} />
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 1 - Name" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.otherFeeName1"
                    control={control}
                    placeholder="Fee Name"
                    type="text"
                    required={false}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 1 - Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.otherFeeType1"}
                    control={control}
                    options={FEE_TYPES}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 1 - Amount" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.otherFeeAmount1"
                    control={control}
                    placeholder="Fee Amount"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .otherFeeType1 as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 2 - Name" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.otherFeeName2"
                    control={control}
                    placeholder="Fee Name"
                    type="text"
                    required={false}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 2 - Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.otherFeeType2"}
                    control={control}
                    options={FEE_TYPES}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Custom Fee 2 - Amount" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.otherFeeAmount2"
                    control={control}
                    placeholder="Fee Amount"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .otherFeeType2 as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded["mgtFeePanel"]}>
        <StyledAccordionSummary handleChange={handleChange("mgtFeePanel")}>
          <AccordionLabel onClick={handleChange("mgtFeePanel")}>
            Management Fees
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Management Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.nonPerformance.managementFee"
                    control={control}
                    placeholder="value"
                    endAdornment={
                      FEE_TYPE_MAP[
                        formValues.fees.nonPerformance
                          .managementFeeType as keyof typeof FEE_TYPE_MAP
                      ]
                    }
                    disabled={
                      formValues.fees.nonPerformance.managementFeeType ===
                      "scale"
                    }
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Management Fee Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.nonPerformance.managementFeeType"}
                    control={control}
                    options={MGT_FEE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
          {formValues.fees.nonPerformance.managementFeeType === "scale" && (
            <>
              <Divider sx={{ mb: 2 }} />
              <MultiColumnContainer>
                <ColumnItem>
                  <FormRowGridContainer>
                    <FormLabel label="Lower Limit" />
                    <FormRowGridItem>
                      <StyledTextField
                        type="number"
                        value={newLowerLimit}
                        onChange={(e) =>
                          setNewLowerLimit(Number(e.target.value))
                        }
                        endAdornment="$"
                        fullWidth
                      />
                    </FormRowGridItem>
                  </FormRowGridContainer>
                  <FormRowGridContainer>
                    <FormLabel label="Upper Limit" />
                    <FormRowGridItem>
                      <StyledTextField
                        type="number"
                        value={newUpperLimit}
                        onChange={(e) =>
                          setNewUpperLimit(Number(e.target.value))
                        }
                        endAdornment="$"
                        fullWidth
                      />
                    </FormRowGridItem>
                  </FormRowGridContainer>
                  <FormRowGridContainer>
                    <FormLabel label="Fee Amount" />
                    <FormRowGridItem>
                      <StyledTextField
                        type="number"
                        value={newFeeAmount}
                        onChange={(e) =>
                          setNewFeeAmount(Number(e.target.value))
                        }
                        endAdornment="%"
                        fullWidth
                      />
                    </FormRowGridItem>
                  </FormRowGridContainer>
                  <FormRowGridContainer>
                    <FormLabel label="" />
                    <FormRowGridItem>
                      <StyledButton
                        onClick={handleAddFeeLimit}
                        variant="contained"
                      >
                        Add Fee Limit
                      </StyledButton>
                    </FormRowGridItem>
                  </FormRowGridContainer>
                </ColumnItem>
                <ColumnItem>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Lower Limit</TableCell>
                        <TableCell>Upper Limit</TableCell>
                        <TableCell>Fee Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {managementFeeLimits.map((feeLimit: FeeLimit, index) => (
                        <TableRow key={feeLimit.lowerLimit}>
                          <TableCell
                            align="left"
                            sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                          >
                            {fCurrency(feeLimit.lowerLimit)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                          >
                            {fCurrency(feeLimit.upperLimit)}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                          >
                            {feeLimit.feeAmount.toString() + "%"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                          >
                            <IconButton
                              onClick={() => handleRemoveFeeLimit(index)}
                              size="small"
                            >
                              <CloseIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ColumnItem>
              </MultiColumnContainer>
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded["perfFeePanel"]}>
        <StyledAccordionSummary handleChange={handleChange("perfFeePanel")}>
          <AccordionLabel onClick={handleChange("perfFeePanel")}>
            Performance Fees
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Rate Type" />
                <FormRowGridItem>
                  <StringSelectControl
                    name={"fees.performance.rateType"}
                    control={control}
                    options={RATE_TYPES}
                  ></StringSelectControl>
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel
                  label={`Margin Above ${formValues.fees.performance.rateType} Rate`}
                />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.marginAboveRate"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Allowance For RITC" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.allowanceForRitc"
                    control={control}
                    placeholder="value"
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Performance Fee Applicable" />
                <FormRowGridItem>
                  <CheckboxControl
                    name="fees.performance.performanceFeeApplicable"
                    control={control}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label={`Return Above Hurdle`} />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.returnAboveHurdle"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Performance Fee Floor Hurdle" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.performanceFeeFloorHurdle"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Crystalised Performance Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="fees.performance.crystalisedPerformanceFee"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
      <StyledAccordion expanded={expanded["prevPeriodPanel"]}>
        <StyledAccordionSummary handleChange={handleChange("prevPeriodPanel")}>
          <AccordionLabel onClick={handleChange("prevPeriodPanel")}>
            Previous Period Inputs
          </AccordionLabel>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MultiColumnContainer>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Closing Date" />
                <FormRowGridItem>
                  <DatePickerControl
                    name="previousPeriodInputs.closingDate"
                    control={control}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Application Units" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.applicationUnits"
                    control={control}
                    placeholder="value"
                    endAdornment={["#"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="NAV Unit Price" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.navUnitPrice"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Period Under Performance" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.periodUnderPerformance"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
            <ColumnItem>
              <FormRowGridContainer>
                <FormLabel label="Closing NAV" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.closingNav"
                    control={control}
                    placeholder="value"
                    endAdornment={["$"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Redemption Units" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.redemptionUnits"
                    control={control}
                    placeholder="value"
                    endAdornment={["#"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
              <FormRowGridContainer>
                <FormLabel label="Cap On Performance Fee" />
                <FormRowGridItem>
                  <TextFieldControl
                    name="previousPeriodInputs.capOnPerformanceFee"
                    control={control}
                    placeholder="value"
                    endAdornment={["%"]}
                  />
                </FormRowGridItem>
              </FormRowGridContainer>
            </ColumnItem>
          </MultiColumnContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </FormBox>
  );
}
