import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { fCurrency, fNumber, fPercent } from "../../utils/formatNumber";

export interface RowHeader {
  label: string;
  type?: string;
  key?: string;
  sx?: SxProps;
}

export default function InfoMultiTable({
  data,
  rowHeaders,
}: {
  data: Array<unknown>;
  rowHeaders: Array<RowHeader>;
}) {
  const getValue = (obj: unknown, key: string, type?: string) => {
    const value = _.get(obj, key);
    if (value || typeof value === "number") {
      switch (type) {
        case "money":
          return fCurrency(value);
        case "percent":
          return fPercent(value);
        case "integer":
          return fNumber(value, 0, 0);
        case "decimal":
          return fNumber(value, 2, 8);
        case "boolean":
          return value ? "True" : "False";
        default:
          return value.toString();
      }
    } else {
      return "ERR";
    }
  };

  return (
    <Box>
      {rowHeaders.length && data.length && (
        <Table>
          <TableBody>
            {rowHeaders.map((row: RowHeader, d: number) => (
              <TableRow key={d}>
                <TableCell sx={{ p: "2px", border: "none", ...row.sx }}>
                  {row.label}
                </TableCell>
                {data.map((obj, index) => (
                  <TableCell
                    key={index}
                    align="right"
                    sx={{
                      p: "2px",
                      border: "none",
                      ...row.sx,
                      fontWeight: "regular",
                    }}
                  >
                    {row.key ? getValue(obj, row.key, row.type) : ""}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
